$(document).ready(function() {
	'use strict';

	initNavigation();
	initLangSwitch();

	// news lightbox
	if($('.js-hd-news').length) {
		initNewsLightbox();
	}

	// dog detail
	if($('.js-hd-dog-detail').length) {
		initDogDetail();
	}

	// litter detail
	if($('.js-hd-litter-detail').length) {
		initLitterDetail();
	}

	// dog detail
	if($('.js-hd-puppy-detail').length) {
		initPuppyDetail();
	}

	// herding devils slider
	if($('.js-hd-slider').length) {
		initHerdingDevilsSlider();
	}

	// litter stats expander
	if($('.js-litter-stats-expander').length) {
		initLitterStatsExpander();
	}

	// init scroll to top button
	if($('#js-btn-scroll-to-top').length) {
		initScrollToTopButton();
	}

	// Smooth scrolling using jQuery easing
	$('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
		if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
			if (target.length) {
				$('html, body').animate({
					scrollTop: target.offset().top
				}, 1000, "easeInOutExpo");
				return false;
			}
		}
		});

	// Closes responsive menu when a scroll trigger link is clicked
	$('#sidebar-wrapper .js-scroll-trigger').click(function() {
		//alert('bam');
		$("#sidebar-wrapper").removeClass("active");
		$(".menu-toggle").removeClass("active");
		$(".menu-toggle > .fa-bars, .menu-toggle > .fa-times").toggleClass("fa-bars fa-times");
	});

	// Scroll to top button appear
	$(document).scroll(function() {
		var scrollDistance = $(this).scrollTop();
		if (scrollDistance > 100) {
			$('.scroll-to-top').fadeIn();
		} else {
			$('.scroll-to-top').fadeOut();
		}
	});

});

/**********************************************
* functions
***********************************************/
/**********************
* init navigation
***********************/
function initNavigation() {
	'use strict';
	var $navigationToggle = $('#js-nav-toggle');
	var $navigation = $('#js-nav');
	var viewportWidth = 0;

	// toggle the navigation menu
	$navigationToggle.on('click', function(event) {
		event.preventDefault();
		$(this).toggleClass('is-active');
		if($navigation.hasClass('is-active')){
			// close
			$navigation.slideUp('400', function() {
				$navigation.toggleClass('is-active');
			});
		} else {
			//open
			$navigation.slideDown('400', function() {
				$navigation.toggleClass('is-active');
			});
		}
	});

	// toggle navigation expander
	if($('.js-nav-expander-toggle')) {
		var $actNavExpander;
		// close expander initial
		$('.js-nav-expander').removeClass('is-expanded');
		$('.js-nav-expander-content').slideUp('400');
		// react on click
		$('.js-nav-expander-toggle').on('click', function(event) {
			event.preventDefault();
			$actNavExpander = $(this);
			if($actNavExpander.hasClass('is-expanded')) {
				$actNavExpander.removeClass('is-expanded');
				$actNavExpander.closest('li').find('> ul').slideUp('400');
			} else{
				$actNavExpander.addClass('is-expanded');
				$actNavExpander.closest('li').find('> ul').slideDown('400');
			}
			
		});
	}
}

/**********************
* init language switch
***********************/
function initLangSwitch() {
	'use strict';
	var $langToggle = $('#js-switch-lang-toggle');
	var $langContent = $('#js-switch-lang-content');
	var $langMBrp = 575;
	
	var viewportWidth = 0;
	
	// toggle the language switch
	// react on resize
	$(window).on('resize',function(){
		viewportWidth = getViewportWidth();
		if(viewportWidth <= $langMBrp) {
			// mobile
			if($langToggle.hasClass('is-active')) {
				$langToggle.removeClass('is-active');
			}
			$langContent.hide();
		} else {
			// desktop
			if($langToggle.hasClass('is-active')) {
				$langToggle.removeClass('is-active');
			}
			$langContent.show();
		}
	}).resize();
	// react on toggle
	$langToggle.on('click', function(clickEvent){
		clickEvent.preventDefault();
		if($langToggle.hasClass('is-active')){
			// close
			$langToggle.removeClass('is-active');
			$langContent.slideUp('300');
		} else {
			// open
			$langToggle.addClass('is-active');
			$langContent.slideDown('300');
		}
	});
}

/**********************
* init news lightbox
***********************/
function initNewsLightbox() {
	'use strict';

	if($('.js-hd-news .hd-news__content a img').length) {
		$('.js-hd-news .hd-news__content a img').closest('a').addClass('is-image-link').fancybox();
	}
}


/**********************
* init dog detail
***********************/
function initDogDetail() {
	'use strict';
	var $detailNav = $('.js-hd-dog-details-navigation');
	var $navLink, navHref, $navTarget;
	
	var $detailSlider = $('.js-hd-dog-detail-picture-slider');

	// dog detail navigation
	if(typeof $detailNav !== 'undefined' && $detailNav.length) {
		$detailNav.find('a').on('click', function(clickEvent) {
			clickEvent.preventDefault();
			$navLink = $(this);
			navHref = $navLink.attr('href');
			$navTarget = $(navHref);
			if(typeof $navTarget !== 'undefined') {
				$('html, body').animate({
					scrollTop: $navTarget.offset().top
				}, 400);
			}
		});
	}

	// dog detail pictures
	if(typeof $detailSlider !== 'undefined' && $detailSlider.length) {
		$detailSlider.slick(
			{
				infinite: false,
				dots: true,
				arrows: true,
				nextArrow: '<a href="#" class="slick-arrow slick-next" title="Weiter"><i class="fas fa-angle-right"></i><span class="u-screenreader">Weiter</span></a>',
				prevArrow: '<a href="#" class="slick-arrow slick-prev" title="Zur&uuml;ck"><i class="fas fa-angle-left"></i><span class="u-screenreader">Zur&uuml;ck</span></a>',
				speed: 600,
				variableWidth: true,
				slidesToScroll: 1,
				responsive: true,
				verticalSwiping: false
			}
		);

		// fancybox and slick reaction combination 
		$().fancybox({
			selector : '.js-hd-dog-detail-picture-slider .slick-slide > a',
			backFocus : false,
			afterShow : function( instance, current ) {
				$('.js-hd-dog-detail-picture-slider.slick-initialized').slick('slickGoTo', parseInt(current.index), true);
			}
		});
	}
}

/**********************
* init slider
***********************/
function initHerdingDevilsSlider() {
	'use strict';
	$('.js-hd-slider').slick(
		{
			infinite: false,
			autoplay: true,
			autoplaySpeed: 6000,
			pauseOnHover: true,
			dots: true,
			arrows: true,
			nextArrow: '<a href="#" class="slick-arrow slick-next" title="Weiter"><i class="fas fa-angle-right"></i><span class="u-screenreader">Weiter</span></a>',
			prevArrow: '<a href="#" class="slick-arrow slick-prev" title="Zur&uuml;ck"><i class="fas fa-angle-left"></i><span class="u-screenreader">Zur&uuml;ck</span></a>',
			speed: 600,
			slidesToScroll: 1,
			responsive: true,
			verticalSwiping: false
		}
	);
}

/**********************
* init litter detail
***********************/
function initLitterDetail() {
	'use strict';
	var $detailSlider = $('.js-hd-litter-picture-slider');

	// litter detail pictures
	if(typeof $detailSlider !== 'undefined' && $detailSlider.length) {
		$detailSlider.slick(
			{
				infinite: false,
				dots: false,
				arrows: true,
				nextArrow: '<a href="#" class="slick-arrow slick-next" title="Weiter"><i class="fas fa-angle-right"></i><span class="u-screenreader">Weiter</span></a>',
				prevArrow: '<a href="#" class="slick-arrow slick-prev" title="Zur&uuml;ck"><i class="fas fa-angle-left"></i><span class="u-screenreader">Zur&uuml;ck</span></a>',
				speed: 600,
				variableWidth: true,
				slidesToScroll: 1,
				responsive: true,
				verticalSwiping: false
			}
		);
		
		// fancybox and slick reaction combination 
		$().fancybox({
			selector : '.js-hd-litter-picture-slider .slick-slide > a',
			backFocus : false,
			afterShow : function( instance, current ) {
				$('.js-hd-litter-picture-slider.slick-initialized').slick('slickGoTo', parseInt(current.index), true);
			}
		});
	}
}

/**********************
* init puppy detail
***********************/
function initPuppyDetail() {
	'use strict';
	var $detailNav = $('.js-hd-puppy-details-navigation');
	var $navLink, navHref, $navTarget;

	var $detailSlider = $('.js-hd-puppy-detail-picture-slider');

	// puppy detail navigation
	if(typeof $detailNav !== 'undefined' && $detailNav.length) {
		$detailNav.find('a').on('click', function(clickEvent) {
			clickEvent.preventDefault();
			$navLink = $(this);
			navHref = $navLink.attr('href');
			$navTarget = $(navHref);
			if(typeof $navTarget !== 'undefined') {
				$('html, body').animate({
					scrollTop: $navTarget.offset().top
				}, 400);
			}
		});
	}

	// puppy detail pictures
	if(typeof $detailSlider !== 'undefined' && $detailSlider.length) {
		$detailSlider.slick(
			{
				infinite: false,
				dots: false,
				arrows: true,
				nextArrow: '<a href="#" class="slick-arrow slick-next" title="Weiter"><i class="fas fa-angle-right"></i><span class="u-screenreader">Weiter</span></a>',
				prevArrow: '<a href="#" class="slick-arrow slick-prev" title="Zur&uuml;ck"><i class="fas fa-angle-left"></i><span class="u-screenreader">Zur&uuml;ck</span></a>',
				speed: 600,
				variableWidth: true,
				slidesToScroll: 1,
				responsive: true,
				verticalSwiping: false
			}
		);

		// fancybox and slick reaction combination 
		$().fancybox({
			selector : '.js-hd-puppy-detail-picture-slider .slick-slide > a',
			backFocus : false,
			afterShow : function( instance, current ) {
				$('.js-hd-puppy-detail-picture-slider.slick-initialized').slick('slickGoTo', parseInt(current.index), true);
			}
		});
	}
}

/**********************
* init litter stats expander
***********************/
function initLitterStatsExpander() {
	'use strict';
	var $actExpander, $actExpanderContent, viewportWidth, newWidth;
	var firstColWidth =  230;
	var tableViewport = 601;

	// initial close all expander
	$('.js-litter-stats-expander').removeClass('is-expanded');
	$('.js-litter-stats-expander-content').hide();

	// react on interaction
	$('.js-litter-stats-expander-controller').on('click', function(clickEvent) {
		clickEvent.preventDefault();
		$actExpander = $(this).closest('.js-litter-stats-expander');
		$actExpanderContent = $actExpander.find('.js-litter-stats-expander-content');
		
		if($actExpander.hasClass('is-expanded')){
			// close
			$actExpanderContent.slideUp('slow');
			$actExpander.removeClass('is-expanded');
		} else {
			// open
			$actExpander.addClass('is-expanded');
			$actExpanderContent.slideDown('slow');
		}
	});

	// manage litter stats col with
	$(window).on('resize',function(){
		viewportWidth = getViewportWidth();
		if(viewportWidth <= tableViewport) {
			// mobile - remove widths
			$('.js-litter-stats-expander-content .litter-stats__table-col').removeAttr('style');
		} else {
			// desktop
			// remove widths
			$('.js-litter-stats-expander-content .litter-stats__table-col').removeAttr('style');
			clearTimeout(window.resizedFinished);
			window.resizedFinished = setTimeout(function(){
				// get new widths
				var newWidth = (parseInt($('.js-litter-stats-expander').width()) - firstColWidth) / 2;
				// set new widths
				$('.js-litter-stats-expander-content .litter-stats__table-col:not(:first-child)').css('width', newWidth + 'px');
			}, 15);
		}
	}).resize();
}



/**********************
* init scroll to top button
***********************/
function initScrollToTopButton() {
	'use strict';
	var $scrollBtn = $('#js-btn-scroll-to-top');

	// show/hide
	$(window).scroll(function() {
		if ($(window).scrollTop() > 300) {
			$scrollBtn.addClass('is-shown');
		} else {
			$scrollBtn.removeClass('is-shown');
		}
	});

	// react on click
	$scrollBtn.on('click', function(clickEvent){
		clickEvent.preventDefault();
		$('html, body').animate({scrollTop:0}, '300');
	});
}


// Disable Google Maps scrolling
// See http://stackoverflow.com/a/25904582/1607849
// Disable scroll zooming and bind back the click event
var onMapMouseleaveHandler = function(event) {
    var that = $(this);
    that.on('click', onMapClickHandler);
    that.off('mouseleave', onMapMouseleaveHandler);
    that.find('iframe').css("pointer-events", "none");
}
var onMapClickHandler = function(event) {
    var that = $(this);
    // Disable the click handler until the user leaves the map area
    that.off('click', onMapClickHandler);
    // Enable scrolling zoom
    that.find('iframe').css("pointer-events", "auto");
    // Handle the mouse leave event
    that.on('mouseleave', onMapMouseleaveHandler);
}
// Enable map zooming with mouse scroll when the user clicks the map
$('.map').on('click', onMapClickHandler);

/**********************************************
* helper functions
***********************************************/
function getViewportWidth() {
	var viewport = window, innerWidth = 'inner';
	if (!('innerWidth' in window )) {
		innerWidth = 'client';
		viewport = document.documentElement || document.body;
	}
	return viewport[ innerWidth+'Width' ];
}
